import React from 'react';
import { motion } from 'framer-motion';

const ThemedButton = ({ onClick, children }) => {
  return (
    <motion.button
      whileTap={{ scale: 0.9 }}         // Pienenee klikatessa
      whileHover={{ scale: 1.05 }}       // Suurentuu hoverissa
      onClick={onClick}
      className="mx-auto flex items-center justify-center gap-2 px-6 py-3 
             rounded-full shadow-md 
             bg-gradient-to-r from-blue-500 to-purple-500 
             hover:from-purple-500 hover:to-blue-500 
             text-white dark:text-white 
             dark:bg-gradient-to-r dark:from-blue-500 dark:to-gray-400 
             transition-all duration-300 ease-in-out 
             w-full sm:w-auto text-sm"
    >
      {children}
    </motion.button>
  );
};

export default ThemedButton;
