import React from 'react';

// Components
import Navbar from '../../components/Navbar/navbar';
import Intro from '../../components/Intro/intro';
import Skills from '../../components/Skills/skills';
import Works from '../../components/Works/works';
import Contact from '../../components/Contact/contact';
import Footer from '../../components/Footer/footer';
import ITTimeline from '../../components/ITTimeline/ITTimeline';
import About from '../../components/About/About';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'; 
import VisitorCounter from '../../components/VisitorCounter/VisitorCounter';

export default function Home() {
  return (
    <>
      <Navbar />
      <VisitorCounter />
      <div className="container">
        {/* Tarkista, että VisitorCounter esiintyy vain siellä, missä haluat */}
      
        <Intro />
        <About />
        <ITTimeline />
        <Skills />
        <Works />
        <Contact />
      </div>
      
      <Footer />
      <ScrollToTop />
    </>
  );
}
