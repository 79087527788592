import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ThemedButton from "../ThemeButton/ThemedButton";

// Portfolio-kuvien tuonnit
import Portfolio1 from "../../assets/spiderlegs.webp";
import Portfolio2 from "../../assets/joyride.webp";
import Portfolio3 from "../../assets/github.webp";

// Logojen data: määritellään kerran
const logos = [
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/06/Facelogo.jpg",
    text: "Bilebändi OMG logo",
    link: "https://www.facebook.com/Bilebandi0MG",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/06/Joyriden-logo.webp",
    text: "Bilebändi Joyride logo",
    link: "https://bilebandijoyride.com/",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/06/Napatoiston-logo.webp",
    text: "Punkbändi Napatoiston logo",
    link: "https://soundcloud.com/napatoisto-yhtye",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/06/Pro-green-logo.webp",
    text: "Asiakastyö logo",
    link: "https://www.taitotalo.fi/ajankohtaista/hankkeet-ja-projektit-taitotalossa/kansainvalinen-toiminta-erasmushankkeet/erasmus-ka-210-vet-pro-green-way-arts-and-crafts",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/06/spiderlegs.webp",
    text: "Spiderlegs productions logo",
    link: "https://spiderlegsproductions.com/",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/08/Keijot.webp",
    text: "Kouluprojekti logo",
    link: "",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/08/Sivusilmalla_logo.webp",
    text: "Kouluprojekti logo",
    link: "",
  },
  {
    src: "https://bilebandijoyride.com/wp-content/uploads/2024/08/ecocreative.webp",
    text: "Kouluprojekti logo",
    link: "",
  },
];

const Works = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className="light-mode dark:filter-none p-4 w-full">
      <section data-aos="fade-up" id="works" className="space-y-12">
        {/* Otsikko ja kuvaus */}
        <header className="text-center">
          <h2
            title="Portfolio"
            className="worksTitle dark:text-white text-4xl font-bold"
          >
            Portfolio
          </h2>
          <p
            title="Tässä portfoliossa esittelen verkkosivuja, videoeditointitöitä ja graafisia projekteja, joita olen toteuttanut sekä omilla sivuillani että tilaustöinä."
            className="workDesc dark:text-white mt-4 max-w-3xl mx-auto"
          >
            Tässä portfoliossa esittelen verkkosivuja, videoeditointitöitä ja graafisia projekteja, joita olen toteuttanut sekä omilla sivuillani että tilaustöinä.
          </p>
        </header>

        {/* Verkkosivut-osio */}
        <section>
          <h3
            title="Verkkosivut"
            className="web-pages dark:text-white text-2xl font-semibold mb-6 underline text-center"
          >
            Verkkosivut
          </h3>
          <div className="worksImgs grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Kortti: Spiderlegs productions */}
            <div className="work-item bg-white dark:bg-blue-800 rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl">
              <a
                href="https://spiderlegsproductions.com/"
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <img
                  src={Portfolio1}
                  alt="Spiderlegs productions verkkosivut"
                  className="w-full p-4 object-cover"
                  loading="lazy"
                />
              </a>
              <div className="p-4 text-center">
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  Spiderlegs productions
                </h4>
                <p className="text-gray-700 dark:text-gray-300 text-sm leading-relaxed">
                  Äänentoiston ja esitystekniikan vuokrausyritykseni verkkosivut, toteutettu Reactilla ja Tailwind CSS:llä.
                </p>
                <div className="flex justify-center mt-5">
                  <ThemedButton
                    onClick={() =>
                      window.open("https://spiderlegsproductions.com/", "_blank")
                    }
                  >
                    Tutustu sivustoon
                  </ThemedButton>
                </div>
              </div>
            </div>

            {/* Kortti: Bilebändi Joyride */}
            <div className="work-item bg-white dark:bg-blue-800 rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl">
              <a
                href="https://bilebandijoyride.com/"
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <img
                  src={Portfolio2}
                  alt="Bilebändi Joyride verkkosivut"
                  className="w-full p-4 object-cover"
                  loading="lazy"
                />
              </a>
              <div className="p-4 text-center">
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  Bilebändi Joyride
                </h4>
                <p className="text-gray-700 dark:text-gray-300 text-sm leading-relaxed">
                  Verkkosivut, jotka heijastavat intohimoani musiikkiin. Rakennettu WordPressillä ja Bootstrapilla.
                </p>
                <div className="flex justify-center mt-5">
                  <ThemedButton
                    onClick={() =>
                      window.open("https://bilebandijoyride.com/", "_blank")
                    }
                  >
                    Katso sivusto
                  </ThemedButton>
                </div>
              </div>
            </div>

            {/* Kortti: GitHub digitaalinen CV */}
            <div className="work-item bg-white dark:bg-blue-800 rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl">
              <a
                href="https://arttustenius.github.io/"
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <img
                  src={Portfolio3}
                  alt="GitHub digitaalinen CV"
                  className="w-full p-4 object-cover"
                  loading="lazy"
                />
              </a>
              <div className="p-4 text-center">
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  GitHub digitaalinen CV
                </h4>
                <p className="text-gray-700 dark:text-gray-300 text-sm leading-relaxed">
                  Digitaalinen ansioluetteloni, rakennettu HTML:llä, CSS:llä ja JavaScriptillä.
                </p>
                <div className="flex justify-center mt-5">
                  <ThemedButton
                    onClick={() =>
                      window.open("https://arttustenius.github.io/", "_blank")
                    }
                  >
                    Näytä CV
                  </ThemedButton>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Videoeditointi-osio */}
        <section>
          <h3
            title="Videoeditointi"
            className="web-pages dark:text-white text-2xl font-semibold text-center mb-6"
          >
            Videoeditointi
          </h3>
          <div className="youtubevids grid grid-cols-1 md:grid-cols-2 gap-6 place-items-center md:place-items-start">
            <div className="video-item p-2 rounded-lg w-full max-w-md">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.youtube.com/embed/nE5wx_BZWZ0"
                  title="Bilebändi Joyride promoviideo"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="video-item p-2 rounded-lg w-full max-w-md">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.youtube.com/embed/7MPRXiMVJJo"
                  title="Bilebändi Renki promoviideo"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="video-item p-2 rounded-lg w-full max-w-md">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.youtube.com/embed/Nh46J4hoiSE"
                  title="Napatoisto - Haapona on ra..."
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="video-item p-2 rounded-lg w-full max-w-md">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.youtube.com/embed/bHVq9o7ktDc"
                  title="Vanha video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="video-item p-2 rounded-lg w-full max-w-md">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.youtube.com/embed/AjVsYdQ9mAo"
                  title="Spiderlegs productionsin valopromovideo"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="video-item p-2 rounded-lg w-full max-w-md">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.youtube.com/embed/xYuvqijBV9M"
                  title="Punkbändi Korjosen Annelin ensimmäinen biisi ja video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        {/* Logojen Grid Section */}
        <section>
          <h3
            title="Kuvankäsittely"
            className="text-2xl font-semibold mb-6 text-center dark:text-white"
          >
            Logot
          </h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4">
            {logos.map((logo, index) => (
              <div key={index} className="flex flex-col items-center">
                {logo.link ? (
                  <a href={logo.link} target="_blank" rel="noreferrer">
                    <img
                      src={logo.src}
                      alt={logo.text}
                      className="rounded-md hover:opacity-75 transition-opacity w-16 sm:w-20 md:w-24 lg:w-28 object-contain mx-auto"
                    />
                  </a>
                ) : (
                  <img
                    src={logo.src}
                    alt={logo.text}
                    className="rounded-md hover:opacity-75 transition-opacity w-16 sm:w-20 md:w-24 lg:w-28 object-contain mx-auto"
                  />
                )}
                <p className="mt-2 text-xs dark:text-white text-center">{logo.text}</p>
              </div>
            ))}
          </div>
        </section>
      </section>
    </div>
  );
};

export default Works;
