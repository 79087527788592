import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const VisitorCounter = () => {
  const [count, setCount] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  // Aseta oikea API-osoite – tässä käytetään suhteellista polkua, jos React ja API ovat samassa domainissa
  const endpoint = "/api/visitor.php";

  // Haetaan laskurin arvo, kun komponentti ladataan
  useEffect(() => {
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        console.log("Initial count fetched:", data);
        if (data.count !== undefined) {
          setCount(data.count);
        }
      })
      .catch((error) => {
        console.error("Virhe kävijälukua haettaessa:", error);
        // Jos API:a ei löydy, simuloidaan aloitusarvoa
        setCount(0);
      });
  }, [endpoint]);

  // Funktio, joka kasvattaa laskuria napin klikkauksella (vain kerran)
  const incrementCount = () => {
    if (isClicked) return; // Jos nappia on jo painettu, ei tehdä mitään
    console.log("Nappi klikattu, lähetetään POST-pyyntö...");
    fetch(endpoint, { method: "POST" })
      .then((response) => response.json())
      .then((data) => {
        console.log("POST-pyynnön vastaus:", data);
        if (data.count !== undefined) {
          setCount(data.count);
          setIsClicked(true); // Estetään nappia klikkaamasta useampaan kertaan
        } else {
          // Jos vastausta ei saada, simuloidaan päivitys
          setCount((prevCount) => prevCount + 1);
          setIsClicked(true);
        }
      })
      .catch((error) => {
        console.error("Virhe kävijälukua päivittäessä:", error);
        // Jos POST-pyyntö epäonnistuu, simuloidaan vastaus
        setTimeout(() => {
          setCount((prevCount) => prevCount + 1);
          setIsClicked(true);
          console.log("Simuloitu POST-vastaus lokalisti.");
        }, 500);
      });
  };

  return (
    <div className="absolute top-20 left-4 flex items-center space-x-4 p-4 z-30">
      <motion.button
        onClick={incrementCount}
        disabled={isClicked}
        whileHover={{ scale: isClicked ? 1 : 1.1 }}
        whileTap={{ scale: isClicked ? 1 : 0.9 }}
        className={`px-3 py-3 bg-blue-500 text-white rounded-full shadow-md transition-all duration-300 text-sm hover:border-2 ${
          isClicked ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        Tykkää sivustani
      </motion.button>
      <motion.span
        key={count}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
        className="text-xl font-bold text-white text-shadow-md"
      >
        {count}
      </motion.span>
      {isClicked && (
        <motion.div
          className="ml-2 text-white text-sm"
          initial={{ x: 0, opacity: 1 }}
          animate={{ x: 100, opacity: 0 }}
          transition={{ delay: 3, duration: 1 }}
        >
          Kiitos, tykkäyksesi on tallennettu!
        </motion.div>
      )}
    </div>
  );
};

export default VisitorCounter;
