import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';
import AOS from "aos";
import "aos/dist/aos.css";
import './Contact.css';
import { motion } from "framer-motion";

// Tuodaan videotaustat: yksi light-teemalle ja toinen dark-teemalle
import VideoBgLight from '../../assets/background-video-light.mp4';
import VideoBgDark from '../../assets/background-video.mp4';

// Tuodaan React Icons -komponentit
import { FaFacebook, FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';

const Contact = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  // Tilamuuttuja, jonka avulla tiedetään, onko dark-teema käytössä.
  const [isDark, setIsDark] = useState(document.documentElement.classList.contains('dark'));

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
    });
    emailjs.init('WpnoiFGOPNg1kK0Xh'); // Vaihda omaan EmailJS:n public-keyysi

    // MutationObserver seuraa html-elementin class-attribuuttia,
    // ja päivittää tilan, kun dark-mode päälle/pois kytketään.
    const observer = new MutationObserver(() => {
      setIsDark(document.documentElement.classList.contains('dark'));
    });
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });
    return () => observer.disconnect();
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert("Ole hyvä ja varmista CAPTCHA.");
      return;
    }

    emailjs.sendForm('service_idkn6xd', 'template_521aoqb', form.current, 'WpnoiFGOPNg1kK0Xh')
      .then((result) => {
        console.log(result.text);
        e.target.reset();
        setCaptchaVerified(false); // Nollataan CAPTCHA
        alert('Sähköposti lähetetty! Kiitos viestistäsi.');
      }, (error) => {
        console.log(error.text);
        alert('Sähköpostin lähettäminen epäonnistui, yritä myöhemmin uudelleen.');
      });
  };

  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaVerified(true);
  };

  // Valitaan näytettävä videotausta teeman perusteella.
  const videoSrc = isDark ? VideoBgDark : VideoBgLight;

  return (
    // Ulompi kontti, jossa video on taustalla
    <div className="relative min-h-screen light-mode dark:filter-none">
      {/* Videotaustaelementti. Lisäsin key-attribuutin, jotta elementti renderöidään uudelleen, kun videoSrc muuttuu */}
      <video 
        key={videoSrc}
        autoPlay 
        loop 
        muted 
        playsInline 
        className="absolute top-0 left-0 w-full h-full object-cover z-10"
      >
        <source src={videoSrc} type="video/mp4" />
        Selaimesi ei tue videota.
      </video>

      {/* Sisältö, joka tulee videon päälle */}
      <div className="relative z-10 mt-20 p-8">
        <section id="contactPage">
          <section id="contact">
            <div>
              {/* Käytetään tv-effect -luokkaa vanhan tv:n välkkyvän efektin luomiseksi */}
              <h2 
                title="Ota yhteyttä!" 
                className="contactPageTitle text-gray-800 text-shadow-md dark:text-white tv-effect dark:text-shadow-md"
              >
                Ota yhteyttä
              </h2>
              <span 
                title="Olen aktiivisesti etsimässä uusia haasteita front-end-kehittäjänä sekä mielenkiintoisia freelancer-projekteja. Intohimonani on luoda visuaalisesti näyttäviä ja teknisesti toimivia verkkosovelluksia. Etsin projekteja, joissa voin kehittää taitojani entisestään ja tuoda lisäarvoa tiimeille ja asiakkaille."
                className="contactDesc text-gray-800 text-shadow-md dark:text-white tv-effect dark:text-shadow-md"
              >
                Olen aktiivisesti etsimässä uusia haasteita front-end-kehittäjänä sekä mielenkiintoisia freelancer-projekteja. Intohimonani on luoda visuaalisesti näyttäviä ja teknisesti toimivia verkkosovelluksia. Etsin projekteja, joissa voin kehittää taitojani entisestään ja tuoda lisäarvoa tiimeille ja asiakkaille. 
                <br /><br />
                Jos etsit sitoutunutta, luotettavaa ja motivoitunutta kehittäjää, täytä alla oleva lomake – vastaan viestiisi mahdollisimman pian!
              </span>
              <br /><br />
              <h3 
                className="text-gray-800 text-shadow-md dark:text-white tv-effect dark:text-shadow-md" 
                title="Yhteydenottolomake"
              >
                Yhteydenottolomake
              </h3>
              
              {/* Wrapper, joka keskittää lomakkeen */}
              <div className="flex justify-center">
                <form 
                  data-aos="zoom-in" 
                  data-aos-duration="3000" 
                  className="contactForm w-full max-w-lg"
                  ref={form} 
                  onSubmit={sendEmail}
                >
                  <input 
                    title="Kirjoita nimesi" 
                    type="text" 
                    className="name bg-white dark:bg-black dark:text-white text-black w-full mb-4 p-2"
                    placeholder="Nimesi.." 
                    name="user_name" 
                    required 
                  />
                  <input 
                    title="Syötä sähköpostisi" 
                    type="email" 
                    className="email bg-white dark:bg-black dark:text-white text-black w-full mb-4 p-2" 
                    placeholder="Sähköpostisi.." 
                    name="user_email" 
                    required 
                  />
                  <textarea 
                    title="Kirjoita viestisi" 
                    className="msg bg-white dark:bg-black dark:text-white text-black w-full mb-4 p-2" 
                    name="message" 
                    rows="5" 
                    placeholder="Kirjoita viestisi.." 
                    required
                  ></textarea>
                  
                  <ReCAPTCHA
                    sitekey="6LcyYNUqAAAAAKi0klY8JSMv_aZwvnxyCDUin81_"
                    onChange={onCaptchaChange}
                  />
                  
                  <motion.button
                    type="submit"
                    id="input-submit"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    className="submitBtn mx-auto flex items-center justify-center gap-2 px-6 py-3 
                              rounded-full shadow-md 
                              bg-gradient-to-r from-blue-500 to-purple-500 
                              hover:from-purple-500 hover:to-blue-500 
                              text-white dark:text-white 
                              dark:bg-gradient-to-r dark:from-blue-500 dark:to-gray-400 
                              transition-all duration-300 ease-in-out 
                              w-full sm:w-auto text-sm my-10"
                  >
                    Lähetä
                  </motion.button>

                  <div className="links flex space-x-8">
                    {/* Facebook-linkki */}
                    <div className="group relative flex flex-col items-center">
                      <a
                        href="https://www.facebook.com/mrmoonstoner/"
                        target="_blank"
                        rel="noreferrer"
                        title="Henkilökohtainen Facebook"
                        className="transform transition duration-300 hover:scale-110 hover:-translate-y-1"
                      >
                        <FaFacebook 
                          size={24} 
                          className={`animate-pulse transition-colors duration-300 ${isDark ? 'text-white' : 'text-black'}`}
                          style={{ animationDelay: "0s" }}
                        />
                      </a>
                      <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-1 text-xs text-black dark:text-white">
                        Facebook
                      </span>
                    </div>

                    {/* Instagram-linkki */}
                    <div className="group relative flex flex-col items-center">
                      <a
                        href="https://www.instagram.com/mrmoonstoner666/"
                        target="_blank"
                        rel="noreferrer"
                        title="Henkilökohtainen Instagram"
                        className="transform transition duration-300 hover:scale-110 hover:-translate-y-1"
                      >
                        <FaInstagram 
                          size={24} 
                          className={`animate-pulse transition-colors duration-300 ${isDark ? 'text-white' : 'text-black'}`}
                          style={{ animationDelay: "0.2s" }}
                        />
                      </a>
                      <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-1 text-xs text-black dark:text-white">
                        Instagram
                      </span>
                    </div>

                    {/* Linkedin-linkki */}
                    <div className="group relative flex flex-col items-center">
                      <a
                        href="https://www.linkedin.com/in/arttu-stenius/"
                        target="_blank"
                        rel="noreferrer"
                        title="Seuraa minua LinkedInissä"
                        className="transform transition duration-300 hover:scale-110 hover:-translate-y-1"
                      >
                        <FaLinkedin 
                          size={24} 
                          className={`animate-pulse transition-colors duration-300 ${isDark ? 'text-white' : 'text-black'}`}
                          style={{ animationDelay: "0.4s" }}
                        />
                      </a>
                      <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-1 text-xs text-black dark:text-white">
                        Linkedin
                      </span>
                    </div>

                    {/* Github-linkki */}
                    <div className="group relative flex flex-col items-center">
                      <a
                        href="https://github.com/arttustenius"
                        target="_blank"
                        rel="noreferrer"
                        title="Github sivuni"
                        className="transform transition duration-300 hover:scale-110 hover:-translate-y-1"
                      >
                        <FaGithub 
                          size={24} 
                          className={`animate-pulse transition-colors duration-300 ${isDark ? 'text-white' : 'text-black'}`}
                          style={{ animationDelay: "0.6s" }}
                        />
                      </a>
                      <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-1 text-xs text-black dark:text-white">
                        Github
                      </span>
                    </div>
                  </div>
                </form>
              </div> {/* Lopetus: Wrapper, joka keskittää lomakkeen */}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default Contact;
