import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { FiSun, FiMoon, FiMenu, FiX, FiMail } from "react-icons/fi";
import ThemedButton from "../ThemeButton/ThemedButton"; // Varmista, että polku on oikein

const Navbar = () => {
  const [theme, setTheme] = useState("light");
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const closeMobileMenu = () => setShowMenu(false);

  return (
    <nav className="navbar flex items-center z-40 justify-between p-4 bg-white dark:bg-violet shadow-lg transition-colors duration-300">
      {/* Vasen osa: Näytetään mobiilissa "arttustenius.com" */}
      <div className="flex items-center">
        <span className="block md:hidden text-lg font-bold text-darkest dark:text-black">
          arttustenius.com
        </span>
      </div>

      {/* Keskiosa: Desktop-valikko */}
      <div className="hidden md:flex space-x-2">
        <Link
          title="Koti"
          activeClass="active"
          to="intro"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          className="relative inline-block no-underline text-darkest dark:text-black"
        >
          <span className="pb-1 desktopMenuListItem">Koti</span>
          <motion.div
            className="absolute left-0 bottom-0 h-0.5 bg-blue-500"
            initial={{ scaleX: 0 }}
            whileHover={{ scaleX: 1 }}
            transition={{ duration: 0.3 }}
            style={{ originX: 0 }}
          />
        </Link>
        <Link
          title="Minusta"
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          className="relative inline-block no-underline text-darkest dark:text-black"
        >
          <span className="pb-1 desktopMenuListItem">Minusta</span>
          <motion.div
            className="absolute left-0 bottom-0 h-0.5 bg-blue-500"
            initial={{ scaleX: 0 }}
            whileHover={{ scaleX: 1 }}
            transition={{ duration: 0.3 }}
            style={{ originX: 0 }}
          />
        </Link>
        <Link
          title="Aikajana"
          activeClass="active"
          to="ITTimeline"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          className="relative inline-block no-underline text-darkest dark:text-black"
        >
          <span className="pb-1 desktopMenuListItem">Aikajana</span>
          <motion.div
            className="absolute left-0 bottom-0 h-0.5 bg-blue-500"
            initial={{ scaleX: 0 }}
            whileHover={{ scaleX: 1 }}
            transition={{ duration: 0.3 }}
            style={{ originX: 0 }}
          />
        </Link>
        <Link
          title="Osaaminen"
          activeClass="active"
          to="skills"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          className="relative inline-block no-underline text-darkest dark:text-black"
        >
          <span className="pb-1 desktopMenuListItem">Osaaminen</span>
          <motion.div
            className="absolute left-0 bottom-0 h-0.5 bg-blue-500"
            initial={{ scaleX: 0 }}
            whileHover={{ scaleX: 1 }}
            transition={{ duration: 0.3 }}
            style={{ originX: 0 }}
          />
        </Link>
        <Link
          title="Portfolio"
          activeClass="active"
          to="works"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          className="relative inline-block no-underline text-darkest dark:text-black"
        >
          <span className="pb-1 desktopMenuListItem">Portfolio</span>
          <motion.div
            className="absolute left-0 bottom-0 h-0.5 bg-blue-500"
            initial={{ scaleX: 0 }}
            whileHover={{ scaleX: 1 }}
            transition={{ duration: 0.3 }}
            style={{ originX: 0 }}
          />
        </Link>
      </div>

      {/* Oikea osa: Teeman vaihto, desktop "Ota yhteyttä" ja mobiilivalikon kuvake */}
      <div className="flex items-center space-x-4">
        {/* Teeman vaihto nappi */}
        <button onClick={toggleTheme} aria-label="Vaihda teemaa" className="focus:outline-none">
          {theme === "light" ? (
            <FiMoon size={30} className="text-black transition-colors duration-300 cursor-pointer" />
          ) : (
            <FiSun size={30} className="text-yellow-400 transition-colors duration-300 cursor-pointer" />
          )}
        </button>

        {/* Desktop "Ota yhteyttä" -nappula näkyy vain desktopissa */}
        <motion.button
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.05 }}
          className="hidden md:flex items-center justify-center gap-2 rounded-full shadow-md bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500 text-white dark:text-white dark:bg-gradient-to-r dark:from-blue-500 dark:to-gray-400 transition-all duration-300 ease-in-out text-sm px-4 py-2"
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
        >
          <FiMail className="w-6 h-6" />
          <span className="font-semibold tracking-wide">Ota yhteyttä!</span>
        </motion.button>

        {/* Mobiilivalikon avaava menu-ikoni */}
        <button
          onClick={() => {
            setShowMenu(!showMenu);
            console.log("showMenu:", !showMenu);
          }}
          className="md:hidden focus:outline-none"
          aria-label="Avaa valikko"
        >
          {showMenu ? (
            <FiX size={40} className="text-black transition-colors duration-300" />
          ) : (
            <FiMenu size={40} className="text-black transition-colors duration-300" />
          )}
        </button>
      </div>

      {/* Mobiilivalikko (näkyy vain pienillä näytöillä) sisältää "Ota yhteyttä" -nappulan */}
      {showMenu && (
        <div className="navMenu absolute top-full left-0 w-full bg-white dark:bg-violet flex flex-col items-center space-y-2 py-4 md:hidden">
          <Link
            activeClass="active"
            to="intro"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="listItem text-darkest"
            onClick={closeMobileMenu}
          >
            Koti
          </Link>
          <Link
            title="Minusta"
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="listItem text-darkest"
            onClick={closeMobileMenu}
          >
            Minusta
          </Link>
          <Link
            title="Aikajana"
            activeClass="active"
            to="ITTimeline"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="listItem text-darkest"
            onClick={closeMobileMenu}
          >
            Aikajana
          </Link>
          <Link
            title="Osaaminen"
            activeClass="active"
            to="skills"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="listItem text-darkest"
            onClick={closeMobileMenu}
          >
            Osaaminen
          </Link>
          <Link
            activeClass="active"
            to="works"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="listItem text-darkest"
            onClick={closeMobileMenu}
          >
            Portfolio
          </Link>
          <ThemedButton
            onClick={() => {
              closeMobileMenu();
              document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
            }}
            className="hover:underline"
          >
            Ota yhteyttä!
          </ThemedButton>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
