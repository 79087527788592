import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

import darkModeImage from "../../assets/logo800px.webp";
import lightModeImage from "../../assets/logo800px2.webp";  // Uusi kuva vaalealle teemalle
import lightVideo from "../../assets/light.mp4";
import darkVideo from "../../assets/header_video6.mp4";

const Intro = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    document.documentElement.classList.contains("dark")
  );
  const [fadeClass, setFadeClass] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
    });

    const observer = new MutationObserver(() => {
      setIsDarkMode(document.documentElement.classList.contains("dark"));
      setFadeClass("fade-in"); // Käynnistetään feidaus-animaatio
      setTimeout(() => setFadeClass(""), 1000); // Poistetaan luokka 1s jälkeen
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="mb-40 relative">
      <section id="intro" className="z-20">
        <video
          autoPlay
          muted
          loop
          id="videoScreen"
          key={isDarkMode ? "dark" : "light"}
        >
          <source src={isDarkMode ? darkVideo : lightVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Vasemmalle keskelle vertikaalisesti: Sosiaalisen median jako-linkit */}
        <div className="absolute left-7 top-1/2 transform -translate-y-1/2 z-30 flex flex-col space-y-4 items-center">
          <span className="text-blue-500 dark:text-white text-sm font-semibold rotate-90">
            Jaa
          </span>
          <a
            href={`https://www.facebook.com/dialog/share?app_id=1167231504751263&display=popup&href=${encodeURIComponent(
              window.location.href
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Jaa Facebookissa"
          >
            <FaFacebook size={30} className="text-blue-500 dark:text-white hover:text-blue-800" />
          </a>

          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              window.location.href
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Jaa LinkedInissä"
          >
            <FaLinkedin size={30} className="text-blue-500 dark:text-white hover:text-blue-800" />
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
            title="Instagram"
          >
            <FaInstagram size={30} className="text-blue-500 dark:text-white hover:text-blue-800" />
          </a>
        </div>

        <div className="introContent">
          <span title="Hei!" className="hello text-white">
            Hei!
          </span>

          <span
            title="Minä olen Arttu, kokenut front-end ohjelmistokehittäjä"
            className="introtext text-white"
          >
            Minä olen <span className="introName text-bluedark">Arttu</span> <br />
            front-end ohjelmistokehittäjä
          </span>

          <p
            title="Keski-Uudeltamaalta, Keravalta. Suunnittelen ja luon käyttäjäystävällisiä, responsiivisia verkkosivuja ja kaunista grafiikkaa."
            className="introPara text-white"
          >
            Keski-Uudeltamaalta, Keravalta.
          </p>
        </div>

        <img
          src={isDarkMode ? darkModeImage : lightModeImage}  // Vaihtaa kuvan teeman mukaan
          alt="Arttu Stenius profiilikuva"
          className={`bg ${fadeClass}`}
          title="Arttu Stenius"
        />
      </section>
    </div>
  );
};

export default Intro;
