import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const timelineItems = [
  {
    date: "2013",
    title: "WordPress-seikkailu",
    description:
      "Aloitin WordPressin opettelun ja rakensin ensimmäiset verkkosivut bilebändilleni, mikä avasi oven digitaaliseen maailmaan.",
  },
  {
    date: "2014 - 2022",
    title: "WordPress-ylläpitoa ja kehitystä",
    description:
      "Kehitin ja ylläpidin omia verkkosivuja useiden vuosien ajan, kartuttaen kokemusta ja omaksuen uusia tekniikoita.",
  },
  {
    date: "10/2023",
    title: "Opinnot Taitotalossa",
    description:
      "Aloitin opinnot Taitotalossa front-end-kehityksen parissa ja syvennyin ohjelmoinnin sekä käyttöliittymäsuunnittelun saloihin. Tavoitteenani oli edetä koulutuksessa mahdollisimman nopeasti.",
  },
  {
    date: "10/2024 - 31.12.2024",
    title: "Timehouse Oy / Ohjelmistokehittäjä (työharjoittelu)",
    description:
      "Aloitin 3kk työharjoittelun Timehouse Oy:llä. Sain osallistua mielenkiintoisiin projekteihin ja oppia paljon uusia asioita.",
  },
  {
    date: "01/2025",
    title: "Valmistuminen",
    description:
      "Suoritin kaksi viimeistä näyttöä erinomaisin arvosanoin, mikä merkitsi koulutukseni päättymistä sekä tärkeää virstanpylvästä opinnoissani, valmistumista.",
  },
  {
    date: "02.01.2025 - 28.02.2025",
    title: "Timehouse Oy / Ohjelmistokehittäjä (työsuhde)",
    description:
      "Työsuhteessa Timehouse Oy:ssä junior front-end kehittäjänä, jossa vastasin käyttöliittymien suunnittelusta sekä verkkosivujen kehittämisestä useassa asiakasprojektissa.",
  },
];

const ITTimeline = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <div className="container mx-auto p-4">
      <section id="ITTimeline" className="relative py-12">
        <h2
          className="text-3xl font-bold text-center dark:text-white mb-12"
          data-aos="fade-down"
          title="IT-alan aikajana"
        >
          IT-alan aikajana
        </h2>

        <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full border-l-4 border-gray-300 dark:border-white transition-transform duration-500"></div>

        {timelineItems.map((item, index) => {
          const alignment = index % 2 === 0 ? "left" : "right";
          const textAnimation = alignment === "left" ? "fade-right" : "fade-left";

          return (
            <div key={index} className="mb-12 flex flex-col md:flex-row items-center overflow-hidden relative">
              {alignment === "left" ? (
                <>
                  <div
                    className="w-full md:w-1/2 p-4 md:text-right dark:text-white"
                    data-aos={textAnimation}
                    data-aos-delay={`${index * 200}`}
                  >
                    <h3 className="text-xl font-semibold border-l-4 border-blue-500 pl-4">
                      {item.date} – {item.title}
                    </h3>
                    <p className="text-gray-700 dark:text-gray-300">
                      {item.description}
                    </p>
                  </div>
                  <div className="w-full md:w-1/2"></div>
                </>
              ) : (
                <>
                  <div className="w-full md:w-1/2"></div>
                  <div
                    className="w-full md:w-1/2 p-4 md:text-left"
                    data-aos={textAnimation}
                    data-aos-delay={`${index * 200}`}
                  >
                    <h3 className="text-xl dark:text-white font-semibold border-r-4 border-blue-500 pr-4">
                      {item.date} – {item.title}
                    </h3>
                    <p className="text-gray-700 dark:text-gray-300">
                      {item.description}
                    </p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default ITTimeline;
